import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

const HeroSlider = () => {

  return (
    <>
    <Swiper
      modules={[Pagination]}
      className="heroSlider"
      speed={'1000'}
      pagination={true}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
      }}
    >
      <SwiperSlide>
        <div>
          <div className="w-[255px] lg:w-full mx-auto">
            <iframe data-sal="fade" data-sal-duration="1000" className="w-[255px] h-[255px] mx-auto pointer-events-none" title="hand" src="https://lottie.host/?file=ae46b9da-f774-4854-bda3-c17db9797798/q8B1SYZ5lo.json"></iframe>
          </div>
          <h2 data-sal="slide-up" data-sal-duration="1000" className="font-heavy text-24 lg:text-32 text-snow-100 uppercase mb-5 lg:mb-6 mt-3">Highly<br/> Rated APP</h2>
          <p data-sal="slide-up" data-sal-duration="1000" data-sal-delay="500" className="text-snow-100 max-w-[360px] mx-auto">The Dabble app was the 2nd highest ranked fantasy app in January, above DraftKings, FanDuel, and Underdog.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div>
          <div className="w-[255px] lg:w-full mx-auto">
            <iframe data-sal="fade" data-sal-duration="1000" className="w-[255px] h-[255px] mx-auto pointer-events-none" title="rocket" src="https://lottie.host/?file=4065528b-4d72-4f46-b879-0502720714d3/VfKx1CRLKY.lottie"></iframe>
          </div>
          <h2 data-sal="slide-up" data-sal-duration="1000" className="font-heavy text-24 lg:text-32 text-snow-100 uppercase mb-5 lg:mb-6 mt-3">1,000,000+<br className="hidden lg:inline-block" /> Global Users</h2>
          <p data-sal="slide-up" data-sal-duration="1000" data-sal-delay="500" className="text-snow-100 max-w-[360px] mx-auto">Dabble is trusted and loved by more than 1 million users around the world.</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="w-[255px] lg:w-full mx-auto">
              <iframe  data-sal="fade" data-sal-duration="1000" className="w-[255px] h-[255px] mx-auto pointer-events-none" title="celebrate"  src="https://lottie.host/?file=8840c260-db7a-4209-8789-6c3db30e9300/j5HKTEsVo6.json"></iframe>
            </div>
            <h2 data-sal="slide-up" data-sal-duration="1000" className="font-heavy text-24 lg:text-32 text-snow-100 uppercase mb-5 lg:mb-6 mt-3">$25m+<br className="hidden lg:inline-block" /> in Payouts</h2>
            <p data-sal="slide-up" data-sal-duration="1000" data-sal-delay="500" className="text-snow-100 max-w-[360px] mx-auto">We process thousands of payouts every day, join in the fun!</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HeroSlider;
