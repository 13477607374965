import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import EmojiFollow from './emojiFollow';
import EmojiPick from './emojiPick';
import EmojiEntry from './emojiEntry';
import EmojiBanter from './emojiBanter';

import CardFollow from '../assets/i/card_follow.png';
import CardPick from '../assets/i/card_pick.png';
import CardEntry from '../assets/i/card_entry.png';
import CardPanter from '../assets/i/card_banter.png';

const SocialSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = useRef(null);

  const slides = [
    {
      title: 'FOLLOW OTHER users',
      text: 'Once you follow another user, their activity will show up in your feed automatically.',
      emoji: <EmojiFollow />,
      image: CardFollow,
    },
    {
      title: 'Copy PICKS',
      text: 'Found a pick you like? Tap ‘Copy Entry’ and enter your stake. You will be tailing in a matter of seconds!',
      emoji: <EmojiPick />,
      image: CardPick,
    },
    {
      title: 'Entry description',
      text: 'Whether you’ve studied the stats or just know your players - this is your chance to share your knowledge (or humour).',
      emoji: <EmojiEntry />,
      image: CardEntry,
    },
    {
      title: 'Jump into banter',
      text: 'Like to chat and talk fantasy with others? Become part of the community and chat all things sports - anytime you like. ',
      emoji: <EmojiBanter />,
      image: CardPanter,
    },
  ];

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.realIndex);
  };

  const handleTitleClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <>
      <div className="grid lg:grid-cols-2 lg:gap-20">
        <div>
          <div className="feature-section">
            {slides.map((slide, index) => (
              <div key={index} className={`title mb-5 lg:mb-8 cursor-pointer ${activeSlide === index ? 'active' : ''}`} onClick={() => handleTitleClick(index)}>
                <div className="flex items-center gap-4 h-[75px] lg:h-auto">
                  {slide.emoji}
                  <h3 className="text-snow-100 hover:text-lemon-100 font-heavy uppercase uppercase text-24 lg:text-32 mb-2">{slide.title}</h3>
                </div>
                <p className="text-snow-100 h-[110px] lg:h-auto">{slide.text}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:my-auto">
          <Swiper
            ref={swiperRef}
            effect={'cards'}
            grabCursor={true}
            mousewheel={{
              forceToAxis: true,
            }}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            cardsEffect={{
              perSlideOffset: 10,
              perSlideRotate: 0,
            }}
            modules={[EffectCards, Mousewheel, Pagination]}
            className="socialSlider"
            speed={1000}
            onSlideChange={handleSlideChange}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="card">
                  <img src={slide.image} alt="icon" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SocialSlider;
