import React from 'react';

const Close = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="2.41421" y1="1" x2="20.799" y2="19.3848" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <line x1="1" y1="19.5858" x2="19.3848" y2="1.20101" stroke="white" stroke-width="2" stroke-linecap="round"/>
    </svg>
  );
}
export default Close;