import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

import Stars from '../svg/stars'
import Heart from '../svg/heart'
import Fires from '../svg/fires'
import Salute from '../svg/salute'

const EffectCardSlider = () => {
  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards, Pagination]}
        className="effectCards"
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        speed={1000}
        pagination={true}
      >
        <SwiperSlide>
          <div className="card p-5 text-center">
            <Stars className="self-start" />
            <p>“The most social way to play Daily Fantasy Sports”</p>
            <p className="text-lemon-100">Mullen</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card p-5 text-center">
            <Heart className="self-start" />
            <p>“@dabble great app! Been telling all my friends about it.”</p>
            <p className="text-lemon-100">deny</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card p-5 text-center">
            <Fires className="self-start" />
            <p>“I am loving this app! I play a ton of these apps, your app is amazing with all this put together. Thank you.”</p>
            <p className="text-lemon-100">NextUpCards</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card p-5 text-center">
            <Salute className="self-start" />
            <p>“Going to be up there with the big dogs! Such an amazing idea of merging several apps.”</p>
            <p className="text-lemon-100">Matt Rary</p>
          </div>
        </SwiperSlide>

      </Swiper>
    </>
  );
};

export default EffectCardSlider;
