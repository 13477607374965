import React from 'react';

const Burger = () => {
  return (
    <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="1" x2="27" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <line x1="1" y1="8" x2="27" y2="8" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <line x1="1" y1="15" x2="27" y2="15" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
}
export default Burger;