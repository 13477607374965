import React from 'react';

const Twitter = ({className}) => {
  return (

    <svg className={className} width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0883932 0L14.1135 18.7532L0 34H3.17664L15.5333 20.6509L25.5168 34H36.3262L21.5117 14.1922L34.6486 0H31.472L20.0925 12.2939L10.8979 0H0.0883932ZM4.75978 2.34027H9.72561L31.6542 31.6603H26.6884L4.75978 2.34027Z" fill="white"/>
    </svg>

  );
}
export default Twitter;