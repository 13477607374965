import React from 'react';

const SubTitle = ({ title, content }) => {
  return (
    <div className="mb-11 lg:mb-14 pt-[60px] md:text-center">
      <h2 className="mb-4 lg:mb-8 text-lavender-200 font-heavy uppercase text-46 lg:text-56" data-sal="slide-up" data-sal-duration="1000">{title}</h2>
      <div className="text-lavender-100"  data-sal="slide-up" data-sal-duration="1000">
        {content.map((paragraph, index) => (
          <p className="mt-4 lg:mt-6" key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default SubTitle;
