import React from 'react';

const Fires = ({className}) => {
  return (
  <svg className={className} width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.18002 18.166C4.18002 18.166 3.71552 15.5231 1.01883 14.2176C2.07193 18.6394 1.24637 19.5729 0.550574 22.1745C-0.147107 24.7761 -0.660495 27.8567 2.12835 30.6781C4.91719 33.5013 9.10139 35.2238 13.3947 35.2238C17.6879 35.2238 21.6521 32.8777 23.8524 28.7358C26.0526 24.5939 25.6502 20.5986 23.4123 16.4942C21.1745 12.3881 16.3302 9.8973 16.5126 5.90194C15.2639 6.95384 15.6682 9.12715 15.6682 9.12715C15.6682 9.12715 11.2283 3.81315 14.1995 0C10.0548 1.79575 3.53123 10.2523 4.18002 18.166Z" fill="#FF8080"/>
    <path d="M10.6431 10.6074C10.6431 10.6074 10.7089 14.1895 9.21577 18.1661C7.72262 22.1427 5.86464 23.1382 5.96431 20.0877C3.60799 22.8734 3.40866 26.0554 3.90512 28.9387C4.40346 31.8221 6.1392 35.2427 12.6553 35.207C19.1713 35.1713 21.129 29.9343 21.3283 27.1824C21.5277 24.4306 20.5648 22.1108 19.901 21.3819C19.0378 23.0068 18.0092 22.9091 18.0092 22.9091C18.0092 22.9091 19.4027 17.9689 16.6815 14.7869C16.2828 16.2783 15.288 16.577 15.288 16.577C15.288 16.577 15.0567 12.3393 10.6431 10.6074Z" fill="#FFEF5A"/>
    <path d="M14.3281 22.4976C14.3281 22.4976 10.7438 24.0003 8.82 27.4152C6.89621 30.8301 8.69777 35.4754 12.6582 35.2087C16.6186 34.942 18.6759 32.686 18.5762 29.7689C18.4766 26.8517 16.6299 26.1003 15.4339 25.2381C14.2397 24.376 14.3281 22.4976 14.3281 22.4976Z" fill="#FF8080"/>
  </svg>

);
};

export default Fires;
