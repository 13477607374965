import React, {useEffect, useState} from 'react';
import Logo from './svg/dabbleLogo';
// import AppStore from "./svg/appStore";
// import GooglePlay from "./svg/googlePlay";
import TopSeparator from "./svg/topSeparator";
import BottomSeparator from "./svg/bottomSeparator";
import SubTitle from "./components/subTitle";
import EffectCardSlider from "./components/effectCards"
import HeroSlider from "./components/heroSlider"
import SocialSlider from "./components/socialSlider"
import FeatureSlider2 from "./components/featureSlider"
import Cta from "./components/cta"
import Confetti from "./components/confetti"
// import Features from "./components/features"
import Instagram from "./svg/instagram"
import Facebook from "./svg/facebook"
import Twitter from "./svg/twitter"
import AgeRestriction from "./svg/ageRestriction"
import Burger from "./svg/burger"
import Close from "./svg/close"

import sal from 'sal.js'; // Import Sal.js
import 'sal.js/dist/sal.css'; // Import Sal.js CSS for styling
// import { Player } from '@lottiefiles/react-lottie-player';
import * as LottiePlayer from "@lottiefiles/lottie-player";


import './App.css';

function App() {

  const [isNavVisible, setIsNavVisible] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };
  const handleNavItemClick = () => {
    setIsNavVisible(false);
    document.body.classList.remove('no-scroll');
  };

  useEffect(() => {
    sal({});
    const wrapper = document.querySelector('.wrapper');
    const handleScroll = () => {
      setShowConfetti(false);
    };

    if (isNavVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    if (wrapper) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    // Add isNavVisible to the dependency array
  }, [isNavVisible]);


  return (
    <>
      {showConfetti && (
          <Confetti />
      )}
      <div className="wrapper overflow-x-hidden">
      <section className="bg-lavender-100  justify-between flex flex-col "> {/* px-6  pb-28  */}
        <div className="flex flex-col justify-between h-screen">

          <div className={`burger flex flex-col gap-2 z-50 absolute self-end right-5 top-5 lg:hidden ${
            isNavVisible ? 'visible' : ''
          }`} onClick={toggleNavVisibility}>

            {
              isNavVisible ? <Close /> : <Burger />
            }
         </div>

          <div className={`navbar h-screen w-full lg:w-auto lg:h-auto bg-lavender-200 lg:bg-transparent absolute top-0 left-0 lg:relative z-30 ${
            isNavVisible ? 'visible' : ''
          }`}>
            <nav className="lg:mt-5 w-full h-full lg:w-auto lg:h-auto">
              <ul className="flex items-center justify-center text-center flex-col lg:flex-row gap-8 lg:gap-12 text-snow-100 w-full h-full lg:w-auto lg:h-auto">
                {/*
                 <li>
                  <a className="transition hover:text-lemon-100" href="#about">About</a>
                </li>
                */}
                <li>
                  <a className="transition hover:text-lemon-100" onClick={handleNavItemClick} href="#features">Features</a>
                </li>
                <li>
                  <a className="transition hover:text-lemon-100" onClick={handleNavItemClick}  href="#where-can-i-play">Where can I play</a>
                </li>
                <li>
                  <a className="transition hover:text-lemon-100" href="https://forms.gle/DyR5GpPfUNFQfRY57" target="_blank" rel="noreferrer">Affiliates</a>
                </li>
                <li>
                  <a className="transition hover:text-lemon-100" href="https://dabbleshop.co" target="_blank" rel="noreferrer">Shop</a>
                </li>
                <li>
                  <a className="transition hover:text-lemon-100" onClick={handleNavItemClick}  href="#access">Contact</a>
                </li>
                  <li>
                      <a  className="transition hover:text-lemon-100" href="https://support.dabble.com/hc/en-us/articles/7981414498703-Our-commitment-to-Responsible-Gaming" target="_blank" rel="noreferrer">Responsible Gaming</a>
                  </li>
                  <li>
                      <a  className="transition hover:text-lemon-100" href="https://dabble.teamtailor.com" target="_blank" rel="noreferrer">Careers</a>
                  </li>
              </ul>
            </nav>
          </div>


          {/* to-do -  navbar */}

          <div data-sal="zoom-in" data-sal-duration="1000" className="relative z-40 mt-5 lg:mt-0" > {/* mt-4 lg:mt-10 mb-8 lg:mb-[68px] */}
            <Logo className={'mx-auto mb-3'}/>
            <span className="text-white text-center block font-heavy uppercase title-shadow text-42 lg:text-62">Fantasy</span>
          </div>

          <div>
            <h1 className="text-center text-lemon-100 flex flex-col lg:gap-3 justify-center uppercase font-heavy title-shadow">
              <span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100" className="block text-[30px] lg:text-48">you could</span>
              <span className="flex flex-col lg:flex-row justify-center text-64 lg:text-140"><span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="200">win up to&nbsp;</span><span data-sal="zoom-in" data-sal-duration="1000" data-sal-delay="300"  className="text-140">1,000x</span></span>
              <span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400" className="block text-[26px] lg:text-64">your money on sports</span>
            </h1>
          </div>

            <div className="text-center mx-auto"> {/* mt-4 lg:mt-8 mb-10 lg:mb-2 */}
              <div className="w-[720px] h-auto max-w-full hidden lg:block">
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src="https://lottie.host/69370899-1059-4160-a2dc-a9cb425d2e70/qVs4Nqq4fI.json"
                ></lottie-player>
              </div>

              <div className="lg:hidden w-screen mb-5">
                <lottie-player
                  autoplay
                  loop
                  mode="normal"
                  src="https://lottie.host/9e99ab47-6d22-461f-a3f7-fb038ea8f2ce/VbjHDS3gN3.json"
                ></lottie-player>
              </div>
            </div>
          <div>
            <div className="pb-28 lg:pb-10">
              <Cta
                className={'mx-auto w-[300px] lg:w-[380px]'}
                link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
                text={'Download Now'}
              />

            </div>
          </div>
          </div>
        </section>


          <section className="bg-lavender-100 px-6 justify-between flex flex-col pb-28"> {/* pb-28  */}
              <div className="relative max-w-[100%] lg:max-w-5xl text-center lg:mx-auto mt-5 lg:mt-28"> {/*  */}

                  <div className="relative">

                      <svg id="rotate-star" className="absolute left-0 -top-[46px] scale-[0.82] lg:scale-100" width="92" height="75" viewBox="0 0 92 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M86.8066 31.3363L90.2501 30.5501C91.1002 30.3547 91.1912 29.2136 90.3828 28.886L87.1073 27.5642C85.084 26.7473 83.5623 25.0599 82.9906 22.9973L81.8202 18.7822C81.601 17.988 80.4784 17.8985 80.1362 18.648L78.3128 22.6244C77.4216 24.5705 75.6518 25.9954 73.5247 26.4815L70.0811 27.2678C69.231 27.4631 69.1401 28.6043 69.9485 28.9318L73.224 30.2537C75.2473 31.0706 76.769 32.7579 77.3407 34.8206L78.5111 39.0357C78.7302 39.8299 79.8529 39.9194 80.1951 39.1699L82.0184 35.1934C82.9097 33.2474 84.6794 31.8224 86.8066 31.3363Z" fill="#FCFF4E"/>
                          <path d="M61.9091 10.1535L64.3961 9.58566C65.0101 9.44458 65.0758 8.6204 64.4919 8.38384L62.1263 7.4292C60.665 6.83919 59.566 5.62056 59.1531 4.13088L58.3078 1.08663C58.1496 0.51302 57.3388 0.44839 57.0916 0.989682L55.7747 3.86159C55.1311 5.26705 53.8529 6.2962 52.3166 6.64727L49.8296 7.21512C49.2157 7.3562 49.15 8.18038 49.7338 8.41694L52.0995 9.37158C53.5608 9.96159 54.6598 11.1802 55.0726 12.6699L55.9179 15.7142C56.0762 16.2878 56.887 16.3524 57.1342 15.8111L58.451 12.9392C59.0947 11.5337 60.3729 10.5046 61.9091 10.1535Z" fill="#FCFF4E"/>
                          <path d="M47.0072 51.2682L55.7895 49.2641C57.9514 48.7717 58.184 45.8537 56.1274 45.025L47.7735 41.655C42.6165 39.5724 38.7343 35.268 37.269 30.0075L34.2842 19.2563C33.7189 17.2235 30.8606 16.9956 29.987 18.9138L25.3367 29.0564C23.0633 34.0189 18.5482 37.6539 13.1198 38.8928L4.33748 40.8969C2.17565 41.3893 1.94306 44.3073 3.99959 45.1359L12.3536 48.506C17.5105 50.5886 21.3928 54.893 22.858 60.1535L25.8428 70.9047C26.4082 72.9375 29.2664 73.1653 30.14 71.2472L34.7903 61.1046C37.0638 56.1421 41.5789 52.5071 47.0072 51.2682Z" fill="#FCFF4E">
                              <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 29.5 45.5" to="360 29.5 45.5" dur="5s" additive="sum" repeatCount="indefinite" />
                          </path>
                      </svg>

                      <div className="star-line left-line"></div>

                  </div>

                  <h2 className="py-[34px] lg:py-[74px] uppercase font-heavy text-center lg:text-left lg:flex lg:gap-10 justify-center items-center">
                      <span className="text-snow-100 flex mb-2 lg:mb-0 justify-center lg:justify-start"
                            data-sal="zoom-out" data-sal-duration="1000"
                      >
                          <span className="text-[94px] lg:text-[114.2px] -mt-[22px] lg:-mt-[25px] text-shadow ">$</span>
                          <span className="text-166 lg:text-202 self-start text-shadow ">25</span></span>
                      <span className="text-lemon-100 text-35 lg:text-74 title-shadow"
                            data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100"
                      >GET $25<br className="lg:hidden" /> WHEN YOU SIGN<br className="lg:hidden" /> UP TO DABBLE</span>
                  </h2>

                  <div className="relative">

                      <svg className="absolute right-0 -top-[30px] scale-[0.82] lg:scale-100" width="94" height="81" viewBox="0 0 94 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.56259 49.6503L0.932598 50.7892C0.0365868 51.0717 0.0365868 52.3047 0.932598 52.5872L4.56259 53.7261C6.80491 54.4301 8.58315 56.1115 9.37347 58.2772L10.9909 62.7028C11.2942 63.5368 12.5072 63.5368 12.8105 62.7028L14.4279 58.2772C15.2182 56.1115 16.9965 54.4301 19.2388 53.7261L22.8688 52.5872C23.7648 52.3047 23.7648 51.0717 22.8688 50.7892L19.2388 49.6503C16.9965 48.9463 15.2182 47.2649 14.4279 45.0992L12.8105 40.6736C12.5072 39.8396 11.2942 39.8396 10.9909 40.6736L9.37347 45.0992C8.58315 47.2649 6.80491 48.9463 4.56259 49.6503Z" fill="#FCFF4E"/>
                          <path d="M33.107 70.263L30.4853 71.0856C29.8382 71.2896 29.8382 72.1801 30.4853 72.3841L33.107 73.2067C34.7265 73.7151 36.0107 74.9295 36.5815 76.4936L37.7497 79.6898C37.9687 80.2922 38.8448 80.2922 39.0638 79.6898L40.2319 76.4936C40.8027 74.9295 42.087 73.7151 43.7065 73.2067L46.3281 72.3841C46.9753 72.1801 46.9753 71.2896 46.3281 71.0856L43.7065 70.263C42.087 69.7546 40.8027 68.5402 40.2319 66.9761L39.0638 63.7799C38.8448 63.1775 37.9687 63.1775 37.7497 63.7799L36.5815 66.9761C36.0107 68.5402 34.7265 69.7546 33.107 70.263Z" fill="#FCFF4E"/>
                          <path d="M45.5893 24.8436L36.3313 27.7471C34.0523 28.4608 34.0523 31.6137 36.3313 32.3274L45.5893 35.2308C51.3045 37.0256 55.8412 41.3149 57.8647 46.8376L61.9896 58.1256C62.7705 60.2599 65.8589 60.2599 66.6327 58.1256L70.7577 46.8376C72.774 41.3149 77.3107 37.0256 83.033 35.2308L92.291 32.3274C94.57 31.6137 94.57 28.4608 92.291 27.7471L83.033 24.8436C77.3178 23.0489 72.7811 18.7596 70.7577 13.2369L66.6327 1.94884C65.8518 -0.185421 62.7634 -0.185421 61.9896 1.94884L57.8647 13.2369C55.8483 18.7596 51.3116 23.0489 45.5893 24.8436Z" fill="#FCFF4E">

                              <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 61.9896 35.5" to="360 61.9896 35.5" dur="5s" additive="sum" repeatCount="indefinite" />
                          </path>
                      </svg>

                      <div className="star-line right-line"></div>
                  </div>

              </div>
          </section>

        <section className="bg-lavender-100 px-6 justify-between flex flex-col pb-28"> {/* pb-28  */}
        <div className="relative text-center max-w-[100%] lg:max-w-5xl mx-auto mt-5 lg:mt-28"> {/*  */}
          <HeroSlider />
          <Cta className={'mx-auto w-[300px] lg:w-[380px] mt-10 lg:mt-20'}
               link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
               text={'Download Now'}
          />
        </div>
      </section>
      <TopSeparator className="w-full h-auto -mt-px" />
      <section className="px-6 pb-28">

        <div className="lg:max-w-4xl mx-auto">
          <SubTitle
            title="How to play"
            content={[
              "Dabble's Daily Fantasy Sports app is lightning fast and easy to use. You can create an account, add funds, and start playing in less than 1 minute. Here’s how:"
            ]}
          />
        </div>


        <div className="mt-11 lg:mt-14">
          <div className="relative max-w-[100%] lg:max-w-5xl mx-auto">

          <FeatureSlider2 />

            <Cta className={'mx-auto w-[300px] lg:w-[380px] mt-10 lg:mt-20'}
                 link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
                 text={'Download Now'}
            />
          </div>
        </div>
      </section>
      <BottomSeparator className="w-full h-auto -mb-px" />
      <section id="features"  className="bg-lavender-100 px-6 pb-28">

        <div className="mb-11 lg:mb-14 pt-[60px] lg:text-center">
          <h2 className="text-snow-100 font-heavy uppercase uppercase text-46 lg:text-56">Social features</h2>
        </div>

        <div className="lg:max-w-5xl mx-auto">
          {/*  <Features />
          <hr/> */}
          <SocialSlider />
        </div>

        <Cta className={'mx-auto w-[300px] lg:w-[380px] mt-10'}
             link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
             text={'Download Now'}
        />
      </section>
      <TopSeparator className="w-full h-auto -mt-px" />
      <section id="where-can-i-play" className="px-6 pb-28">

        <div className="lg:max-w-4xl mx-auto">
          <SubTitle
            title="Available in 25 states"
            content={[
              "Dabble's social features can be accessed from anywhere in the United States, however not all games are available to be played in every US state.",
              "Dabble's real-money games can be played in the following states:",
            ]}
          />
        </div>

        <div className="lg:max-w-4xl mx-auto">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 text-lavender-100"  data-sal="fade" data-sal-duration="1000">
              <p>Alaska</p>
              <p>Arkansas</p>
              <p>California</p>
              <p>District of Columbia</p>
              <p>Florida</p>
              <p>Georgia</p>
              <p>Illinois</p>
              <p>Indiana</p>
              <p>Kansas</p>
              <p>Kentucky</p>
              <p>Minnesota</p>
              <p>Nebraska</p>
              <p>New Mexico</p>
              <p>North Carolina</p>
              <p>North Dakota</p>
              <p>Oklahoma</p>
              <p>Oregon</p>
              <p>Rhode Island</p>
              <p>South Carolina</p>
              <p>South Dakota</p>
              <p>Texas</p>
              <p>Utah</p>
              <p>West Virginia</p>
              <p>Wisconsin</p>
              <p>Wyoming</p>
          </div>
          <Cta className={'mx-auto w-[300px] lg:w-[380px] mt-10 lg:mt-20'}
               link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
               text={'Download Now'}
          />
        </div>
      </section>
      <BottomSeparator className="w-full h-auto -mb-px" />
      <section  className="bg-lavender-100 px-6 pb-28 pt-[60px]">
        <div className="lg:max-w-5xl mx-auto">
          <div className="lg:flex lg:gap-28">
            <div className="lg:w-fit mb-6 lg:mb-12 lg:my-auto flex flex-col">


              <div className="lg:order-2">
                <EffectCardSlider />
              </div>

              <div className="mb-7 lg:mb-10 lg:order-1 mt-12 lg:mt-0">
                <Logo className={'mx-auto mb-3 lg:mb-5'}/>
                <span className="text-white text-center block font-heavy uppercase title-shadow text-69 lg:text-74">Fantasy</span>
              </div>


            </div>

            <div className="lg:flex-grow lg:my-auto">
              <div>

                <p className="text-center text-lemon-100 flex flex-col lg:gap-2 justify-center uppercase font-heavy title-shadow">
                  <span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100" className="block text-[30px] lg:text-46">you could win</span>
                  <span className="flex flex-col lg:flex-row justify-center text-64 lg:text-101"><span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="200">up to 1,000x</span></span>
                  <span data-sal="slide-up" data-sal-duration="1000" data-sal-delay="400" className="block text-[26px] lg:text-44">your money on sports</span>
                </p>

                <p className="text-snow-100 my-6 lg:my-9">What are you waiting for? Download Dabble Fantasy and become part of the fastest growing DFS community.</p>

                <Cta
                  className={'mx-auto w-[300px] lg:w-[380px]'}
                  link={'https://apps.apple.com/us/app/dabble-fantasy-pick-em/id6467868797'}
                  text={'Download Now'}
                />




                {/*
                <Cta className={'mx-auto w-[300px] lg:w-[380px] mb-10 lg:mb-20'} />
                <div className="flex gap-4 justify-center">
                  <AppStore className="" />
                  <GooglePlay className="" />
                </div>
                */}

              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="access">
        <footer className="bg-lavender-200 text-snow-100 px-6">
          <div className="lg:max-w-4xl mx-auto py-2 lg:py-10">
            <div className="flex gap-11 lg:gap-16 flex-col lg:flex-row">

              <div className="flex flex-row lg:flex-col justify-between items-center lg:items-start">
                <div className="lg:mb-5">
                  <p className="mb-2">Follow us</p>
                  <div className="flex gap-4 flex-row ">
                    <a href="https://www.instagram.com/dabblefantasy/" target="_blank" rel="noreferrer">
                      <Instagram className={''}/>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61550633383930" target="_blank" rel="noreferrer">
                      <Facebook className={''}/>
                    </a>
                    <a href="https://twitter.com/dabbleSports" target="_blank" rel="noreferrer">
                      <Twitter className={''}/>
                    </a>
                  </div>
                </div>

                <AgeRestriction className={''} />
              </div>

              <div className="lg:flex-grow text-center lg:text-left">
                <p className="mb-4">Dabble is committed to providing a safe and responsible environment for players to enjoy real money Daily Fantasy Sports games.</p>

                <p className="mb-4">Before you use Dabble, you will need to agree to our <a className="underline" href="https://support.dabble.com/hc/en-us/articles/7127409424527-Privacy-Policy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a className="underline" href="https://support.dabble.com/hc/en-us/articles/7892400375183-General-Dabble-Terms-and-Conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>. If you need any further assistance, please contact <a
                  className="underline" href="mailto:support@dabble.com.au">support@dabble.com</a></p>

<p class="mb-4">For media enquiries, please contact <a class="underline" href="mailto:media@dabble.com">media@dabble.com</a></p>

                <p>© Dabble Sports LLC</p><p>600 Congress Ave, Lvl 14, Austin Texas 78704</p>
              </div>

            </div>
          </div>
        </footer>
      </section>
      </div>
    </>
  );
}

export default App;
