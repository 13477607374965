import React from 'react';

import MoneyEmoji from '../assets/i/money.gif'
import PickImage from '../assets/i/social_pick.png'
const EmojiPick = () => {
  return (
    <div className="relative">
      <img className="absolute top-2 left-0 w-[30px] rotate-[4.487deg]" src={MoneyEmoji} alt="icon" />
      <img className="w-[90px] ps-[18px]" src={PickImage} alt="icon" />
    </div>
  );
};

export default EmojiPick;
