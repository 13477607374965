import React, { useEffect } from 'react';
import '../Confetti.scss';

const Confetti = () => {
  const confettiContainerRef = React.useRef(null);

  useEffect(() => {
    const confettiContainer = confettiContainerRef.current;

    // Function to add the 'play-confetti' class and start animation
    const startAnimation = () => {
      confettiContainer.classList.add('play-confetti');

      // Set a timeout to remove the 'play-confetti' class after 2 seconds
      setTimeout(() => {
        confettiContainer.classList.remove('play-confetti');
      }, 3500);
    };

    // Calculate the initial delay based on screen size
    const initialDelay = window.innerWidth < 1023 ? 9000 : 7000;

    // Initial delay for the first confetti
    setTimeout(() => {
      startAnimation();

      // Set an interval to start the animation every 15 seconds
      const intervalId = setInterval(startAnimation, 15000);

      // Clean up the intervals to prevent memory leaks
      return () => {
        clearInterval(intervalId);
      };
    }, initialDelay);
  }, []);

  const confettiElements = [...Array(150)].map((_, i) => (
    <div key={i} className={`confetti-${i}`}></div>
  ));

  return (
    <div className="confetti-container" ref={confettiContainerRef}>
      {confettiElements}
    </div>
  );
};

export default Confetti;
