import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; // Add this line for the navigation

import RegisterImage from '../assets/i/card_register.png';
import FundsImage from '../assets/i/card_addfunds.png';
import PlayersImage from '../assets/i/card_pickplayers.png';
import AmountImage from '../assets/i/card_amount.png';
import SubmitImage from '../assets/i/card_submit.png';

const FeatureSlider2 = () => {

  const CustomPrevArrow = () => (
    <div className="custom-arrow custom-prev-arrow">

    </div>
  );

  const CustomNextArrow = () => (
    <div className="custom-arrow custom-next-arrow">

    </div>
  );

  return (
<>
    <CustomPrevArrow />
  <CustomNextArrow />
      <Swiper
        observer={true}
        observeParents={true}
        className="featureSlider"
        centeredSlides={true}
        grabCursor={true}
        speed={1000}
        mousewheel={{
          forceToAxis: true
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true
        }}
        navigation={{
          prevEl: '.custom-prev-arrow',
          nextEl: '.custom-next-arrow',
        }}
        modules={[Mousewheel, Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
        }}
      >
        <SwiperSlide>
          <img className="max-w-[300px] mx-auto" src={RegisterImage} alt="Register" />
          <h2 className="font-heavy text-24 lg:text-32 text-lavender-200 uppercase mb-5 lg:mb-6 mt-4 lg:mt-8 text-center">STEP 1: REGISTER Account</h2>
          <p className="text-lavender-100 lg:text-center max-w-[360px] mx-auto">We legally need to verify your identity before you can play our games. We will need some personal details, including your age. This is to ensure the safety of you and every other user on the Dabble app.</p>
        </SwiperSlide>
        <SwiperSlide>
          <img className="max-w-[300px] mx-auto" src={FundsImage} alt="Register" />
          <h2 className="font-heavy text-24 lg:text-32 text-lavender-200 uppercase mb-5 lg:mb-6 mt-4 lg:mt-8 text-center">STEP 2: ADD FUNDS</h2>
          <p className="text-lavender-100 lg:text-center max-w-[360px] mx-auto">You can start playing on Dabble with as little as $5. Make a safe, fast, and secure deposit using your debit or credit card. Your funds will be added straight away.</p>
        </SwiperSlide>
        <SwiperSlide>
          <img className="max-w-[300px] mx-auto" src={PlayersImage} alt="Register" />
          <h2 className="font-heavy text-24 lg:text-32 text-lavender-200 uppercase mb-5 lg:mb-6 mt-4 lg:mt-8 text-center">STEP 3: PICK 2-12 PLAYERS</h2>
          <p className="text-lavender-100 lg:text-center max-w-[360px] mx-auto">Now is your time to shine. Use your skill to pick between 2-12 player related performance stats of your choice (e.g. Points, Strikeouts, Passing Yards, and many more). Your pick will be either ‘MORE’ or ‘LESS’ than each player’s projected result for that stat type. </p>
        </SwiperSlide>
        <SwiperSlide>
          <img className="max-w-[300px] mx-auto" src={AmountImage} alt="Register" />
          <h2 className="font-heavy text-24 lg:text-32 text-lavender-200 uppercase mb-5 lg:mb-6 mt-4 lg:mt-8 text-center">STEP 4: SELECT AMOUNT</h2>
          <p className="text-lavender-100 lg:text-center max-w-[360px] mx-auto">Depending on how many selections you make, you can earn a fixed payout of between 3x and 1000x your stake.</p>
        </SwiperSlide>
        <SwiperSlide>
          <img className="max-w-[300px] mx-auto" src={SubmitImage} alt="Register" />
          <h2 className="font-heavy text-24 lg:text-32 text-lavender-200 uppercase mb-5 lg:mb-6 mt-4 lg:mt-8 text-center">STEP 5: SUBMIT ENTRY</h2>
          <p className="text-lavender-100 lg:text-center max-w-[360px] mx-auto">Once you’re ready to go, hit the ‘Place Entry’ button and you are on. It’s that easy!</p>
        </SwiperSlide>
      </Swiper>
</>
  );
};

export default FeatureSlider2;
