import React from 'react';

import FireEmoji from '../assets/i/fire.gif'
import BanterImage from '../assets/i/social_banter.png'
const EmojiBanter = () => {
  return (
    <div className="relative">
      {/*
       <img className="absolute top-[12px] left-0 w-[13px] rotate-[8deg]" src={FireEmoji} alt="icon" />

      */}
      <img className="absolute top-[6px] left-[12px] w-[25px] rotate-[8deg]" src={FireEmoji} alt="icon" />
      <img className="absolute top-[-0px] left-[33px] w-[30px] rotate-[8deg]" src={FireEmoji} alt="icon" />
      <img className="absolute top-[-8px] left-[56px] w-[35px] rotate-[8deg]" src={FireEmoji} alt="icon" />
      <img className="w-[90px] ps-[18px]" src={BanterImage} alt="icon" />
    </div>
  );
};

export default EmojiBanter;
