import React from 'react';

const Facebook = ({className}) => {
  return (
    <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1274_59043)">
        <path d="M35.1787 18.0389C35.1787 8.40937 27.3723 0.603027 17.7428 0.603027C8.11323 0.603027 0.306885 8.40937 0.306885 18.0389C0.306885 26.7415 6.68291 33.955 15.0184 35.263V23.079H10.5913V18.0389H15.0184V14.1976C15.0184 9.82771 17.6215 7.41392 21.6043 7.41392C23.5113 7.41392 25.5072 7.75447 25.5072 7.75447V12.0453H23.3087C21.1428 12.0453 20.4671 13.3895 20.4671 14.7697V18.0389H25.3028L24.5299 23.079H20.4671V35.263C28.8027 33.955 35.1787 26.7415 35.1787 18.0389Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_1274_59043">
          <rect width="34.8718" height="34.8718" fill="white" transform="translate(0.307617 0.602783)"/>
        </clipPath>
      </defs>
    </svg>

  );
}
export default Facebook;