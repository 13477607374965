import React from 'react';

const Salute = ({className}) => {
  return (
  <svg className={className} width="52" height="45" viewBox="0 0 52 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.9086 32C6.66978 32 6.42886 31.9071 6.23212 31.7165C5.78709 31.2883 5.73028 30.5271 6.10377 30.0169C7.14532 28.5935 7.77131 27.1834 7.88809 26.5248C7.54301 26.5392 6.99277 26.6478 6.62875 26.7202C5.21792 27.0012 3.2863 27.3848 2.52249 25.6345C2.15847 24.7986 2.23212 23.9301 2.73186 23.187C3.0559 22.7057 3.5146 22.345 3.91965 22.0266C4.11008 21.877 4.40255 21.6478 4.55195 21.4826C4.31839 21.3269 3.59035 21.2184 3.29156 21.1738C2.1448 21.0025 0.410972 20.7455 0.0480052 18.7299C-0.0845566 17.994 0.058526 17.2992 0.463576 16.7214C0.989615 15.9687 1.9207 15.4693 3.3105 15.1955C4.21634 15.017 5.0054 15.0013 5.03801 15.0001C5.61981 14.9928 6.09745 15.5212 6.10587 16.1882C6.11429 16.8541 5.65137 17.4018 5.07062 17.4126C3.65874 17.4404 2.31418 17.8758 2.10692 18.2256C2.10692 18.2293 2.10797 18.2353 2.10903 18.2401C2.15953 18.5224 2.61823 18.6394 3.563 18.7805C4.64979 18.9422 6.00171 19.1436 6.54879 20.5863C6.85284 21.3873 6.74658 22.216 6.25106 22.918C5.93438 23.3667 5.5146 23.6961 5.10955 24.0145C4.92649 24.1593 4.64979 24.3752 4.49198 24.5416C4.88967 24.6152 5.80077 24.4343 6.26789 24.3414C6.90229 24.2148 7.50303 24.0965 8.04695 24.1122C9.32207 24.152 9.75132 24.9807 9.89335 25.4705C10.4962 27.5453 8.37625 30.661 7.71239 31.5657C7.50408 31.8492 7.20634 31.9952 6.9065 31.9952L6.9086 32Z" fill="#FFEF5A"/>
    <path d="M29.4897 1.95272C31.09 1.26948 33.5158 1.69158 34.3127 1.8599C34.949 1.99428 35.3558 2.61996 35.2214 3.25633C35.087 3.89271 34.4614 4.29947 33.825 4.16509C32.4307 3.87265 30.7648 3.86525 30.3523 4.14128C30.3975 4.66126 30.9607 5.07414 31.7483 5.59871C32.4872 6.09026 33.251 6.59809 33.3879 7.50679C33.4418 7.86421 33.4073 8.41054 32.9383 8.98612C31.9297 10.2212 30.1755 9.97096 28.7667 9.77055C28.5495 9.739 28.2795 9.70057 28.0329 9.67517C28.1323 9.84795 28.2424 10.0277 28.3333 10.1746C28.7346 10.8295 29.1893 11.5705 29.3997 12.3633C29.785 13.818 29.1721 14.7496 28.5888 15.2766C28.0194 15.7913 27.3069 16.0276 26.5265 15.9611C24.2964 15.7693 22.0684 13.062 21.6444 12.5218C21.2429 12.0105 21.3324 11.269 21.8448 10.8671C22.3562 10.4656 23.0976 10.5551 23.4996 11.0675C24.2719 12.0482 25.8338 13.5373 26.7296 13.6134C26.8735 13.6263 26.9366 13.5942 27.0098 13.5284C27.127 13.422 27.2207 13.3384 27.1227 12.9678C27.001 12.5087 26.6419 11.9228 26.3257 11.4071C25.7066 10.3981 25.0664 9.35458 25.5857 8.32071C26.2324 7.03006 27.8228 7.25671 29.0997 7.43916C29.4231 7.48555 30.0093 7.56834 30.475 7.58213C30.4644 7.57516 30.4553 7.5688 30.4446 7.56183C29.5215 6.94781 28.1259 6.01928 28.0014 4.29953C27.9473 3.54889 28.2187 2.87325 28.764 2.39832C28.9751 2.21469 29.2203 2.06899 29.489 1.95427L29.4897 1.95272Z" fill="#FF8080"/>
    <path d="M15 6C16.6569 6 18 4.65685 18 3C18 1.34315 16.6569 0 15 0C13.3431 0 12 1.34315 12 3C12 4.65685 13.3431 6 15 6Z" fill="#FFEF5A"/>
    <path d="M37.5 31C39.433 31 41 29.433 41 27.5C41 25.567 39.433 24 37.5 24C35.567 24 34 25.567 34 27.5C34 29.433 35.567 31 37.5 31Z" fill="#FF8080"/>
    <path d="M10.1522 12.2035C12.6981 13.0124 14.3196 14.4251 15.1096 16.5269C16.1751 19.3622 15.7286 23.515 13.8151 28.539C12.5438 31.8763 10.9986 34.5934 10.5323 35.3842C8.92952 38.1022 12.4257 38.613 13.2242 37.3302C13.3072 37.1956 15.2789 33.9912 16.9004 29.7515C19.1492 23.871 19.594 19.039 18.2231 15.3883C17.0713 12.3233 14.698 10.2018 11.1679 9.07952C10.295 8.80223 9.36058 9.27806 9.08009 10.1395C8.79961 11.0025 9.27925 11.9262 10.1522 12.2035Z" fill="#FF8080"/>
    <path d="M13.2346 40.9523C14.0859 38.8705 16.3188 33.4354 20.7921 27.3989C26.4951 19.7011 33.581 14.0579 41.8535 10.6261C42.7406 10.2576 43.7508 10.6593 44.1118 11.5227C44.4735 12.3931 44.0459 13.3833 43.1589 13.7518C35.4897 16.9354 28.9031 22.1881 23.5854 29.3658C19.3492 35.0844 17.2329 40.2566 16.4362 42.2048C16.4326 42.2152 16.4273 42.2261 16.4231 42.2349C15.6233 45.0998 11.8613 44.3101 13.2346 40.9523Z" fill="#FFEF5A"/>
  </svg>

);
};

export default Salute;
