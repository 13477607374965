import React from 'react';

import WriteEmoji from '../assets/i/write.gif'
import EntryImage from '../assets/i/social_entry.png'
const EmojiEntry = () => {
  return (
    <div className="relative">
      <img className="absolute top-2 left-0 w-[40px] transform -scale-x-100 -rotate-[26deg]" src={WriteEmoji} alt="icon" />
      <img className="w-[90px] ps-[18px]" src={EntryImage} alt="icon" />
    </div>
  );
};

export default EmojiEntry;
