import React from 'react';

const Heart = ({className}) => {
  return (
  <svg className={className} width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.47 0.00258373C20.3822 0.00103513 17.6686 1.64049 16.1113 4.11516C14.5496 1.641 11.837 0.00102782 8.74917 0.00154402C3.91578 0.00154402 -0.0035158 4.01605 4.81994e-06 8.96952C-0.00250853 11.4504 0.978071 13.6933 2.56452 15.3157C2.56452 15.3157 13.8857 28.6807 16.1098 28.6807C18.8857 28.6807 29.6586 15.3137 29.6586 15.3137C31.2431 13.6923 32.2221 11.4494 32.2247 8.97056C32.2231 4.01605 28.3069 -0.0010322 23.473 1.98954e-07L23.47 0.00258373Z" fill="#FF8080"/>
  </svg>

);
};

export default Heart;
