import React from 'react';

import LoveEmoji from '../assets/i/love.gif'
import ProfileImage from '../assets/i/social_profile.png'
const EmojiFollow = () => {
  return (
    <div className="relative">
      <img className="absolute top-[12px] left-0 w-[35px] -rotate-[0.416deg]" src={LoveEmoji} alt="icon" />
      <img className="w-[90px] ps-[18px]" src={ProfileImage} alt="icon" />
    </div>
  );
};

export default EmojiFollow;
