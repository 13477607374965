import React from 'react';

const Stars = ({className}) => {
  return (
    <svg className={className} width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1708 11.4871L25.7311 12.9292C26.6098 13.2866 26.5175 14.5625 25.5965 14.7897L21.8657 15.7044C19.5611 16.2699 17.6494 17.8805 16.6936 20.064L14.738 24.5257C14.371 25.3666 13.1528 25.2785 12.9106 24.3935L11.6175 19.6968C10.9859 17.3985 9.32593 15.5295 7.1267 14.6382L3.56642 13.1961C2.68771 12.8387 2.78001 11.5628 3.701 11.3356L7.43177 10.4209C9.73639 9.85539 11.6481 8.24476 12.6039 6.06129L14.5595 1.5996C14.9265 0.758682 16.1447 0.846809 16.3869 1.73179L17.68 6.42849C18.3116 8.72679 19.9716 10.5958 22.1708 11.4871Z" fill="#FFEF5A"/>
      <path d="M32.3344 34.0788L34.3034 34.8728C34.7893 35.0696 34.7386 35.7713 34.2293 35.8961L32.1666 36.3984C30.8923 36.709 29.8356 37.5944 29.3075 38.7952L28.2271 41.2488C28.0243 41.7113 27.3507 41.6625 27.2166 41.1757L26.5005 38.5921C26.1508 37.3279 25.2325 36.2995 24.0162 35.8088L22.0472 35.0148C21.5612 34.8181 21.612 34.1163 22.1212 33.9916L24.184 33.4893C25.4582 33.1787 26.515 32.2932 27.0431 31.0925L28.1235 28.6388C28.3262 28.1764 28.9999 28.2251 29.134 28.7119L29.85 31.2955C30.1998 32.5598 31.1181 33.5881 32.3344 34.0788Z" fill="#FFEF5A"/>
      <path d="M46.0612 21.584L47.5353 22.1784C47.8991 22.3257 47.8611 22.8511 47.4799 22.9445L45.9356 23.3205C44.9816 23.553 44.1905 24.2159 43.7952 25.1149L42.9863 26.9518C42.8345 27.298 42.3302 27.2615 42.2298 26.8971L41.6937 24.9629C41.4319 24.0164 40.7444 23.2465 39.8338 22.8791L38.3597 22.2847C37.9959 22.1374 38.0339 21.6121 38.4151 21.5187L39.9595 21.1426C40.9134 20.9101 41.7046 20.2472 42.0999 19.3482L42.9087 17.5113C43.0605 17.1651 43.5649 17.2016 43.6653 17.5661L44.2013 19.5003C44.4632 20.4467 45.1507 21.2166 46.0612 21.584Z" fill="#FFEF5A"/>
    </svg>
  );
};

export default Stars;
