import React from 'react';

const Cta = ({className, link, text}) => {
  return (
    <div data-sal="slide-up" data-sal-duration="1000" className={className}>
      <a href={link} target="_blank" rel="noreferrer"
         className="block transition duration-300 linear bg-lemon-100 text-lavender-200 hover:bg-lavender-200 hover:text-lemon-100 p-3 text-20 lg:text-23 w-full rounded-[43px] text-center font-stdblack">
        {text}
      </a> {/* to-do -  link ? */}
    </div>
  );
};

export default Cta;
